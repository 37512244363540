import React from "react";

export default function Docks({ data, setSelected, setStep }: any) {
  return (
    <>
      <h5
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: "10px",
          paddingBottom: "0px",
        }}
      >
        Selecione uma doca
      </h5>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "10px",
          marginBottom: "1rem",
        }}
      >
        {/* <div
          style={{
            display: "flex",
            height: "40px",
            margin: "5px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#dd5400",
            borderRadius: "6px",
            cursor: "pointer",
            color: "white",
            fontWeight: "700",
            width: "100px",
          }}
          onClick={() => setSelectedCompany("")}
        >
          <span>Voltar</span>
        </div> */}
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          padding: "10px",
          paddingTop: "0px",
          marginTop: "0px",
          flexWrap: "wrap",
          justifyContent: "center",
          overflowY: "scroll",
        }}
      >
        {data?.map((item: any, idx: number) => (
          <div
            key={idx}
            style={{
              display: "flex",
              width: "100px",
              height: "100px",
              margin: "5px",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#d3d3d3",
              borderRadius: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelected(item);
              setStep(2);
            }}
          >
            <span>{item.NOMEDOCA}</span>
          </div>
        ))}
      </div>
    </>
  );
}
