import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateInput.css"; // Importe o arquivo CSS

interface CustomDatePickerProps {
  date: any; // Valor atual da data como string
  setDate: (value: any) => void; // Função para atualizar a data
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  date,
  setDate,
}) => {
  const handleDateChange = (date: Date | null) => {
    if (date) {
      setDate(date); // Atualiza a data no formato YYYY-MM-DD
    }
  };

  return (
    <DatePicker
      selected={date ? new Date(date) : null}
      onChange={handleDateChange}
      dateFormat="dd-MM-yyyy"
      className="custom-datepicker" // Adicione a classe CSS
    />
  );
};

export default CustomDatePicker;
