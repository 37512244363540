import React, { useContext } from "react";
import { api } from "../../../services/api";
import { AuthContext } from "../../../Shared/AuthContext";

export default function Orders({
  data,
  setSelected,
  selectedDock,
  setDataLoad,
  setDataLoadItem,
  selectedCompany,
  setStep,
}: any) {
  const { authenticateUser } = useContext(AuthContext);
  const CODUSU = authenticateUser?.idUsuario;

  const handleOrder = async (value: any) => {
    setSelected(value);
    const response = await api.get(
      `load/list/load?CODEMP=${selectedCompany}&CODDOCA=${selectedDock.CODDOCA}&ORDEMCARGA=${value}`
    );

    let data;
    if (!(response?.data?.length > 0)) {
      const response2 = await api.post(`load/save`, {
        CODDOCA: selectedDock.CODDOCA,
        CODEMP: selectedCompany,
        CODUSU,
        HORAINI: new Date(),
        STATUS: "A",
        ORDEMCARGA: value,
      });
      data = response2.data[0];
    } else {
      data = response.data[0];
    }

    setDataLoad(data);

    if (data?.IDAPPCARGA) {
      const responseItem = await api.get(
        `load/list/load-item?IDAPPCARGA=${data?.IDAPPCARGA}`
      );

      setDataLoadItem(responseItem.data);
    }
  };

  return (
    <>
      <h5
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: "10px",
          paddingBottom: "0px",
          flexDirection: "column",
          margin: "10px",
          padding: "10px",
        }}
      >
        <span>Selecione uma ordem de carga </span>
        <span>
          Doca <span style={{ color: "#dd5400" }}>{selectedDock.NOMEDOCA}</span>
        </span>
      </h5>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "10px",
          marginBottom: "1rem",
        }}
      >
        {/* <div
          style={{
            display: "flex",
            height: "40px",
            margin: "5px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#dd5400",
            borderRadius: "6px",
            cursor: "pointer",
            color: "white",
            fontWeight: "700",
            width: "100px",
          }}
          onClick={() => setSelectedDock()}
        >
          <span>Voltar</span>
        </div> */}
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          padding: "10px",
          paddingTop: "0px",
          marginTop: "0px",
          flexWrap: "wrap",
          justifyContent: "center",
          overflowY: "scroll",
        }}
      >
        {data?.map((item: any, idx: number) => (
          <div
            key={idx}
            style={{
              display: "flex",
              width: "100px",
              height: "100px",
              margin: "5px",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#d3d3d3",
              borderRadius: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              setStep(3);
              handleOrder(item);
            }}
          >
            <span>{item}</span>
          </div>
        ))}
      </div>
    </>
  );
}
