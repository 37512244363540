import React, { useContext, useEffect, useState } from "react";
import { NumericInput } from "../../../components/NumericInput";
import { api } from "../../../services/api";
import { AuthContext } from "../../../Shared/AuthContext";
import CustomDatePicker from "../../../components/DateInput";

export default function Register({
  products,
  setSelectedDock,
  setSelectedOrder,
  selectedDock,
  selectedOrder,
  dataLoad,
  selectedCompany,
  setDataLoadItem,
  dataLoadItem,
  setStep,
  step,
  setFooterButtons,
}: any) {
  const [barcode, setBarcode] = useState("");
  const [quantity, setQuantity] = useState("");
  const [validity, setValidity] = useState();
  const [enteredBarcode, setEnteredBarcode] = useState("");
  const [selectedProductDescription, setSelectedProductDescription] =
    useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [messagesValidateDone, setMessagesValidateDone] = useState<String[]>(
    []
  );
  const productNotFoundMessage = `Produto ${barcode} não encontrado!`;

  const { authenticateUser } = useContext(AuthContext);
  const CODUSU = authenticateUser?.idUsuario;

  const handleLoadItens = async () => {
    const responseItem = await api.get(
      `load/list/load-item?IDAPPCARGA=${dataLoad.IDAPPCARGA}`
    );

    setDataLoadItem(responseItem.data);
  };

  useEffect(() => {
    console.log(step);
    if (step === 3) {
      setFooterButtons(
        <div
          style={{
            display: "flex",
            width: "95px",
            height: "40px",
            margin: "5px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#02801c",
            borderRadius: "6px",
            cursor: "pointer",
            color: "white",
            fontWeight: "700",
          }}
          onClick={handleDone}
        >
          <span>Finalizar</span>
        </div>
      );
    }
  }, [step]);

  const handleEnteredBarCode = () => {
    if (barcode !== "") {
      const hasProduct = products?.find(
        (product: any) => product.DUM === barcode
      );
      if (hasProduct) {
        setEnteredBarcode(barcode);
        setSelectedProductDescription(hasProduct.DESCRPROD);
        setStep(4);
        setFooterButtons(<></>);
        return;
      }

      setAlertMessage(productNotFoundMessage);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleEnteredBarCode();
    }
  };

  const handleSave = async () => {
    const makeProduct = products?.find(
      (product: any) => product.DUM === barcode
    );

    if (Number(quantity) > makeProduct.QTDE) {
      setAlertMessage(
        "A quantidade informada é maior que a quantidade da ordem de carregamento!"
      );
      return;
    }

    const sumQuantity = dataLoadItem?.reduce((sum: any, item: any) => {
      if (item.CODPROD === makeProduct.CODPROD) {
        return sum + parseInt(item.QTDE);
      }
      return sum;
    }, 0);

    if (Number(quantity) + sumQuantity > makeProduct.QTDE) {
      setAlertMessage(
        "A quantidade informada mais a soma já lançada é maior que a quantidade da ordem de carregamento!"
      );
      return;
    }

    const data = {
      IDAPPCARGA: dataLoad.IDAPPCARGA,
      CODEMP: selectedCompany,
      ORDEMCARGA: selectedOrder,
      CODPROD: makeProduct.CODPROD,
      QTDE: Number(quantity),
      CODUSU,
      VALIDADE: validity,
      // CODPARC: 123,
    };

    await api.post("load/save/item", data);
    await handleLoadItens();

    setEnteredBarcode("");
    setBarcode("");
    setQuantity("");
    setStep(3);
  };

  const handleDone = async () => {
    const acumularPorCodprod = (array: any[]) => {
      return array.reduce((acc, item) => {
        const codprod = item.CODPROD;
        const qtde = parseInt(item.QTDE);
        if (!acc[codprod]) {
          acc[codprod] = {
            qtde: 0,
            descrprod: item.DESCRPROD?.substring(0, 22),
          };
        }
        acc[codprod].qtde += qtde;
        return acc;
      }, {});
    };

    const qtdeProdutos = acumularPorCodprod(products);
    const qtdeDataLoad = acumularPorCodprod(dataLoadItem);

    const messages: String[] = [];

    Object.keys(qtdeProdutos).forEach((codprod) => {
      const qtde1 = qtdeProdutos[codprod].qtde;
      const qtde2 = qtdeDataLoad[codprod] ? qtdeDataLoad[codprod].qtde : 0;
      const descrprod = qtdeProdutos[codprod].descrprod;

      if (qtde1 !== qtde2) {
        messages.push(
          `${descrprod}`
          // `${descrprod} - esperado: ${qtde1}, encontrado: ${qtde2}`
        );
      }
    });

    if (messages.length > 0) {
      console.log(messages);
      setMessagesValidateDone(messages);

      return;
    }

    await api.post(`load/save`, {
      CODDOCA: selectedDock.CODDOCA,
      CODEMP: selectedCompany,
      CODUSU,
      HORAINI: new Date(),
      STATUS: "F",
      ORDEMCARGA: selectedOrder,
    });

    setSelectedDock();
    setSelectedOrder();
    setStep(3);

    alert("Finalizado com sucesso!");
  };

  const getHeader = (primaryText: string) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: "10px",
          padding: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          backgroundColor: "white",
          flexDirection: "column",
        }}
      >
        <h5
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
            paddingBottom: "0px",
            flexDirection: "column",
            margin: "10px",
            padding: "10px",
          }}
        >
          <span>{primaryText}</span>
          <span>
            Doca{" "}
            <span style={{ color: "#dd5400" }}>{selectedDock.NOMEDOCA}</span>
          </span>
          <span>
            Ordem de Carga{" "}
            <span style={{ color: "#dd5400" }}>{selectedOrder}</span>
          </span>
          {enteredBarcode && (
            <span>
              Código de Barras{" "}
              <span
                style={{ color: "#dd5400" }}
              >{`${enteredBarcode}-${selectedProductDescription}`}</span>
            </span>
          )}
        </h5>
      </div>
    );
  };

  // const getButtons = (showDoneButton = false, backMethod: any) => {
  //   // const width = showProductButton ? "95px" : "150px";

  //   return (
  //     <div
  //       style={{
  //         width: "100%",
  //         display: "flex",
  //         justifyContent: "flex-end",
  //         paddingRight: "10px",
  //         marginBottom: "1rem",
  //       }}
  //     >
  //       {/* <div
  //         style={{
  //           display: "flex",
  //           height: "40px",
  //           margin: "5px",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           backgroundColor: "#dd5400",
  //           borderRadius: "6px",
  //           cursor: "pointer",
  //           color: "white",
  //           fontWeight: "700",
  //           width: "100px",
  //         }}
  //         onClick={backMethod}
  //       >
  //         <span>Voltar</span>
  //       </div> */}

  //       {/*
  //       <div
  //         style={{
  //           display: "flex",
  //           width,
  //           height: "40px",
  //           margin: "5px",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           backgroundColor: "#dd5400",
  //           borderRadius: "6px",
  //           cursor: "pointer",
  //           color: "white",
  //           fontWeight: "700",
  //         }}
  //         onClick={() => {
  //           setSelectedOrder("");
  //         }}
  //       >
  //         <span>O. Carga</span>
  //       </div>
  //       <div
  //         style={{
  //           display: "flex",
  //           width,
  //           height: "40px",
  //           margin: "5px",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           backgroundColor: "#dd5400",
  //           borderRadius: "6px",
  //           cursor: "pointer",
  //           color: "white",
  //           fontWeight: "700",
  //         }}
  //         onClick={() => {
  //           setSelectedDock("");
  //           setSelectedOrder("");
  //         }}
  //       >
  //         <span>Doca</span>
  //       </div>
  //       {showProductButton && (
  //         <div
  //           style={{
  //             display: "flex",
  //             width,
  //             height: "40px",
  //             margin: "5px",
  //             justifyContent: "center",
  //             alignItems: "center",
  //             backgroundColor: "#dd5400",
  //             borderRadius: "6px",
  //             cursor: "pointer",
  //             color: "white",
  //             fontWeight: "700",
  //           }}
  //           onClick={() => {
  //             setEnteredBarcode("");
  //           }}
  //         >
  //           <span>Produto</span>
  //         </div>
  //       )}
  //        */}
  //     </div>
  //   );
  // };

  return (
    <>
      <div>
        {step !== 4 && (
          <>
            {getHeader("Informe o código de barras")}

            <div
              style={{
                display: "flex",
                width: "100%",
                padding: "10px",
                marginTop: "10px",
                flexWrap: "wrap",
                justifyContent: "center",
                flexDirection: "row",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                backgroundColor: "white",
              }}
            >
              <NumericInput
                type="text"
                name="codebar"
                id="codebar"
                placeholder="Código de Barras"
                onChange={(e: any) => {
                  setBarcode(e.target.value);
                  setAlertMessage("");
                  setMessagesValidateDone([]);
                }}
                value={barcode}
                onKeyDown={handleKeyDown}
                style={{ width: "240px" }}
              />
              <div
                style={{
                  display: "flex",
                  width: "60px",
                  height: "50px",
                  marginLeft: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#dd5400",
                  borderRadius: "6px",
                  cursor: "pointer",
                  color: "white",
                  fontWeight: "700",
                }}
                onClick={handleEnteredBarCode}
              >
                <span>Enviar</span>
              </div>
            </div>
          </>
        )}

        {alertMessage !== productNotFoundMessage && step === 4 && (
          <>
            {getHeader("Informe a quantidade")}
            {/* {getButtons(false, () => {
            setEnteredBarcode("");
          })} */}
            <div
              style={{
                display: "flex",
                width: "100%",
                padding: "10px",
                marginTop: "10px",
                flexWrap: "wrap",
                justifyContent: "center",
                flexDirection: "row",
                gap: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                backgroundColor: "white",
                marginLeft: "4px",
                marginRight: "4px",
              }}
            >
              <NumericInput
                type="text"
                name="quantity"
                id="quantity"
                onChange={(e: any) => {
                  setQuantity(e.target.value);
                  setAlertMessage("");
                  setMessagesValidateDone([]);
                }}
                value={quantity}
                placeholder="Quantidade"
                style={{ width: "240px" }}
              />
              <CustomDatePicker date={validity} setDate={setValidity} />

              <div
                style={{
                  display: "flex",
                  width: "60px",
                  height: "50px",
                  marginLeft: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#dd5400",
                  borderRadius: "6px",
                  cursor: "pointer",
                  color: "white",
                  fontWeight: "700",
                }}
                onClick={handleSave}
              >
                <span>Enviar</span>
              </div>
            </div>
          </>
        )}
      </div>
      <>
        {alertMessage && (
          <span
            style={{
              color: "red",
              width: "320px",
            }}
          >
            {alertMessage}
          </span>
        )}
        {messagesValidateDone.length > 0 && (
          <div style={{ overflowY: "scroll" }}>
            <span
              style={{
                color: "red",
                width: "330px",
                marginTop: "1rem",
                marginBottom: "1rem",
                fontSize: "12px",
              }}
            >
              Não é possível finalizar, quantidades divergentes
            </span>
            <span
              style={{
                color: "red",
                width: "310px",
                alignItems: "flex-start",

                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
              }}
            >
              {messagesValidateDone.map((message, idx) => (
                <span key={idx}>{message}</span>
              ))}
            </span>
          </div>
        )}
        <div
          style={{
            width: "310px",
            marginLeft: "-1rem",
            maxHeight: "400px",
            overflowY: "scroll",
          }}
        >
          {dataLoadItem?.length > 0 &&
            dataLoadItem?.map((item: any, idx: number) => (
              <div
                key={idx}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span
                  style={{
                    maxWidth: "260px",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  {item.CODPROD} -{" "}
                  {
                    products.find(
                      (product: any) => product.CODPROD === item.CODPROD
                    ).DESCRPROD
                  }
                </span>
                <span
                  style={{
                    maxWidth: "50px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.QTDE}
                </span>
              </div>
            ))}
        </div>
      </>
    </>
  );
}
