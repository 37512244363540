import jwt_decode from "jwt-decode";

type DataToken = {
  data: {
    idUsuario: string;
  };
};

export class TokenDecode {
  private token: string;

  constructor(token: string) {
    this.token = token;
  }

  getIdUsuario = () => {
    const decoded = jwt_decode(this.token);
    const { idUsuario: idUsuarioEncoded } = (decoded as DataToken).data;

    // Decodificar base64 usando atob (navegador)
    const idUsuario = Number(atob(idUsuarioEncoded));

    return idUsuario;
  };
}
