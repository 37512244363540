import React from "react";

const FloatingFooter = ({ step, setStep, buttons }: any) => {
  // console.log(footerButtons);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        gap: "8px",
        padding: "6px",
        position: "fixed", // Alterado para fixar o footer
        bottom: 0, // Adicionado para fixar o footer no rodapé
        backgroundColor: "white", // Adicionado para garantir que o footer tenha um fundo
        boxShadow: "0 -2px 5px rgba(0,0,0,0.1)", // Adicionado para dar uma sombra ao footer
      }}
    >
      {buttons}
      {step > 1 && (
        <div
          style={{
            display: "flex",
            height: "40px",
            margin: "5px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#dd5400",
            borderRadius: "6px",
            cursor: "pointer",
            color: "white",
            fontWeight: "700",
            width: "100px",
          }}
          onClick={() => {
            setStep((prev: number) => prev - 1);
          }}
        >
          <span>Voltar</span>
        </div>
      )}
    </div>
  );
};

export default FloatingFooter;
